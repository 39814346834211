import React, { useEffect, useState } from "react";
import { getUserDataAPI } from "../../service/UserService";
import store from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { APP_NAME, PERMISSION_MAP, PLATFORM } from "../../utils/constants";

export default function Sidebar() {
  const location = useLocation();
  const [userName, setUserName] = useState("");
  const dispatch = useDispatch();
  const userData = store.getState().account.userData;
  const _isMounted = React.useRef(true);
  const getAccountDetails = async () => {
    const { response } = await getUserDataAPI(
      store.getState().account.userData.id
    );
    dispatch({
      type: "accountData/setUserProfile",
      payload: response,
    });
  };
  useEffect(() => {
    _isMounted.current = true;
    return () => {
      _isMounted.current = false;
    };
  }, []);
  useEffect(() => {
    if (_isMounted.current) {
      setUserName(store.getState().account.userData.username);
      const interval = setInterval(() => {
        getAccountDetails();
      }, 5000);
      return () => clearInterval(interval);
    }
  }, []);

  const [isMobileUI, setIsMobileUI] = useState(window.innerWidth < 768);
  useEffect(() => {
    function handleResize() {
      setIsMobileUI(window.innerWidth < 768);
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const navigate = useNavigate();
  const [currentActive, setCurrentActive] = useState(null);
  const [navs, setNavs] = useState([
    {
      url: "/",
      name: "Dashboard",
      icon: (
        <i
          class="icon icon-inbox"
          style={{ marginLeft: "20px", marginRight: "20px" }}
        />
      ),
    },
    {
      name: userData?.userType + " Details",
      url: "",
      icon: <i class="icon icon-all-contacts" />,
      subNavs: [
        {
          url: "/admin",
          name: "Admin",
          icon: "nav-icon fas fa-chart-bar",
          isPermissionEnabled: true,
          enum: "ADMIN",
        },
        {
          url: "/sub_admin",
          name: "Sub Admin",
          icon: "nav-icon fas fa-address-card",
          enum: "SUB_ADMIN",
          isPermissionEnabled: true,
        },
        {
          url: "/master",
          name: "MasterAgent Master",
          icon: "nav-icon fas fa-address-card",
          enum: "MASTER",
          isPermissionEnabled: true,
        },
        {
          url: "/super_agent",
          name: "SuperAgent Master",
          icon: "nav-icon fas fa-address-card",
          enum: "SUPER_AGENT",
          isPermissionEnabled: true,
        },
        {
          url: "/agent",
          name: "Agent Master",
          icon: "nav-icon fas fa-address-card",
          enum: "AGENT",
          isPermissionEnabled: true,
        },
        {
          url: "/client",
          name: "Client Master",
          icon: "nav-icon fas fa-address-card",
          enum: "CLIENT",
          isPermissionEnabled: true,
        },
      ],
    },

    {
      name: "Sport's Betting",
      url: "",
      icon: <i class="icon icon-navigation" />,
      subNavs: [
        {
          url: "/game/inPlay",
          name: "Current Sport's Details",
          icon: "nav-icon fas fa-chart-bar",
        },
        {
          url: "/game/completeGame",
          name: "Completed Sport's Details",
          icon: "nav-icon fas fa-chart-bar",
        },
      ],
    },
    {
      name: "Casino",
      url: "",
      icon: <i class="icon icon-navigation" />,
      subNavs: [
        {
          url: "/casino/casino-details",
          name: "Casino Details",
          icon: "nav-icon fas fa-chart-bar",
        },
        {
          url: "/casino/casino-position/TEEN_20",
          name: "Live Teen20 Position",
          icon: "nav-icon fas fa-chart-bar",
        },
        {
          url: "/casino/casino-position/DRAGON_TIGER_20",
          name: "Live DragonTiger Position",
          icon: "nav-icon fas fa-chart-bar",
        },
        {
          url: "/casino/casino-position/LUCKY7",
          name: "Live Lucky7 Position",
          icon: "nav-icon fas fa-chart-bar",
        },
        {
          url: "/casino/casino-position/ABJ",
          name: "Live Andar Bahar Position",
          icon: "nav-icon fas fa-chart-bar",
        },
      ],
    },
    {
      name: "Ledger",
      url: "",
      icon: <i class="icon icon-data-entry" />,
      subNavs: [
        {
          url: "/ledger",
          name: "My Ledger",
          icon: "nav-icon fas fa-chart-bar",
        },
        {
          url: "/ledger/admin",
          name: "Admin Ledger",
          icon: "nav-icon fas fa-chart-bar",
          isPermissionEnabled: true,
          enum: "ADMIN",
        },
        {
          url: "/ledger/sub_admin",
          name: "Sub Admin Ledger",
          icon: "nav-icon fas fa-chart-bar",
          isPermissionEnabled: true,
          enum: "SUB_ADMIN",
        },
        {
          url: "/ledger/master",
          name: "Master Ledger",
          icon: "nav-icon fas fa-chart-bar",
          isPermissionEnabled: true,
          enum: "MASTER",
        },
        {
          url: "/ledger/super_agent",
          name: "Super Agent Ledger",
          icon: "nav-icon fas fa-chart-bar",
          isPermissionEnabled: true,
          enum: "SUPER_AGENT",
        },
        {
          url: "/ledger/agent",
          name: "Agent Ledger",
          icon: "nav-icon fas fa-chart-bar",
          isPermissionEnabled: true,
          enum: "AGENT",
        },
        {
          url: "/ledger/client",
          name: "All Client Ledger",
          icon: "nav-icon fas fa-chart-bar",
          isPermissionEnabled: true,
          enum: "CLIENT",
        },
        userData?.userType == "AGENT" && {
          url: "/ledger/commission-lena-dena",
          name: "Comission Lena Dena",
          icon: "nav-icon fas fa-chart-bar",
        },
      ],
    },
    {
      name: "Cash Transaction",
      url: "",
      icon: <i class="icon icon-data-display" />,
      subNavs: [
        {
          url: "/ct/client",
          name: "Debit/Credit Entry (C)",
          icon: "nav-icon fas fa-chart-bar",
          isPermissionEnabled: true,
          enum: "CLIENT",
        },
        {
          url: "/ct/agent",
          name: "Debit/Credit Entry (A)",
          icon: "nav-icon fas fa-chart-bar",
          isPermissionEnabled: true,
          enum: "AGENT",
        },
        {
          url: "/ct/super_agent",
          name: "Debit/Credit Entry (SA)",
          icon: "nav-icon fas fa-chart-bar",
          isPermissionEnabled: true,
          enum: "SUPER_AGENT",
        },
        {
          url: "/ct/master",
          name: "Debit/Credit Entry (M)",
          icon: "nav-icon fas fa-chart-bar",
          isPermissionEnabled: true,
          enum: "MASTER",
        },
        {
          url: "/ct/sub_admin",
          name: "Debit/Credit Entry (SUB)",
          icon: "nav-icon fas fa-chart-bar",
          isPermissionEnabled: true,
          enum: "SUB_ADMIN",
        },
        {
          url: "/ct/admin",
          name: "Debit/Credit Entry (AD)",
          icon: "nav-icon fas fa-chart-bar",
          isPermissionEnabled: true,
          enum: "ADMIN",
        },
      ],
    },
    {
      name: "Reports",
      url: "",
      icon: <i class="icon icon-feedback" />,
      subNavs: [
        {
          url: "/report/account_statement",
          name: "Account Statement",
          icon: "nav-icon fas fa-chart-bar",
        },
        {
          url: "/report/bet_history",
          name: "Bet History",
          icon: "nav-icon fas fa-chart-bar",
        },
        {
          url: "/report/current_bets",
          name: "Current Bets",
          icon: "nav-icon fas fa-chart-bar",
        },
        {
          url: "/report/login",
          name: "Report Details",
          icon: "nav-icon fas fa-chart-bar",
        },
      ],
    },
  ]);

  const sidebarRef = React.useRef();
  const closeSideDrawer = () => {
    const sideBarElement = document.querySelector("#app");
    sideBarElement.classList.remove("is-folded");
    dispatch({
      type: "accountData/setIsSideBarOpen",
      payload: false,
    });
  };

  const handleMenuToggle = () => {
    document.body.classList.toggle("sidebar-open");
  };
  const currentUserType = userData?.userType;
  const isSideBarOpen = useSelector(
    (state) => state.account.userData.isSideBarOpen
  );
  const currentLvl = PERMISSION_MAP[currentUserType];

  useEffect(() => {
    //handle outside click
    function handleClickOutside(event) {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        closeSideDrawer();
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [sidebarRef]);

  return (
    <div _ngcontent-ng-c3705319800="" className="side_nav">
      <app-sidenav
        style={{
          paddingBottom: "20px",
        }}
        _ngcontent-ng-c3705319800=""
        _nghost-ng-c2194352153=""
      >
        <div
          ref={sidebarRef}
          _ngcontent-ng-c2194352153=""
          className="side_nav_inner"
        >
          <ul
            _ngcontent-ng-c2194352153=""
            id="accordionExample"
            className="sideNav_menu"
          >
            {navs.map((nav, index) => {
              return (
                <li _ngcontent-ng-c2194352153="" className="dash_nav_item">
                  <a
                    _ngcontent-ng-c2194352153=""
                    onClick={
                      nav?.subNavs
                        ? (e) => {
                            e.preventDefault();
                          }
                        : () => {
                            navigate(nav.url);
                          }
                    }
                    href={
                      nav?.subNavs ? "javascript:void(0);" : nav.url + "#"
                    }
                    className=""
                    type={nav?.subNavs ? "button" : "link"}
                    data-bs-toggle="collapse"
                    data-bs-target={"#collapseOne" + index}
                    aria-expanded="false"
                    aria-controls="collapseOne"
                  >
                    {nav?.subNavs && (
                      <span
                        style={{
                          marginLeft: "auto",
                          fontSize: "10px",
                          marginRight: "10px",
                        }}
                      >
                        <i class="fa-solid fa-chevron-down" />
                      </span>
                    )}
                    <span
                      _ngcontent-ng-c2194352153=""
                      className="icon_holder"
                    >
                      <div style={{ fontSize: "18px" }}>{nav.icon}</div>
                    </span>
                    <span
                      _ngcontent-ng-c2194352153=""
                      className="title_dash_nav"
                    >
                      {" "}
                      {nav.name}{" "}
                    </span>
                  </a>

                  {nav?.subNavs && (
                    <ul
                      _ngcontent-ng-c2194352153=""
                      id={"collapseOne" + index}
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                      className="subMenuSide collapse"
                      style={{}}
                    >
                      {nav.subNavs.map((subNav, index) => {
                        if (
                          subNav.isPermissionEnabled &&
                          !currentLvl.includes(subNav.enum)
                        )
                          return null;
                        else if (
                          subNav.isPermissionEnabled &&
                          currentLvl.includes(subNav.enum)
                        ) {
                          return (
                            <li _ngcontent-ng-c2194352153="">
                              <Link
                                _ngcontent-ng-c2194352153=""
                                to={subNav.url}
                              >
                                {subNav?.name}
                              </Link>
                            </li>
                          );
                        } else {
                          return (
                            <li _ngcontent-ng-c2194352153="">
                              <a
                                _ngcontent-ng-c2194352153=""
                                href={subNav.url}
                              >
                                {subNav?.name}
                              </a>
                            </li>
                          );
                        }
                      })}
                    </ul>
                  )}
                </li>
              );
            })}
          </ul>

          {/*  <ul
            _ngcontent-ng-c2194352153=""
            id="accordionExample"
            className="sideNav_menu"
          >
            <li _ngcontent-ng-c2194352153="" className="dash_nav_item">
              <a _ngcontent-ng-c2194352153="" href="/" className="">
                <span _ngcontent-ng-c2194352153="" className="icon_holder">
                  <svg _ngcontent-ng-c2194352153="">
                    <use
                      _ngcontent-ng-c2194352153=""
                      xlinkHref="#dashboard-interface"
                    />
                  </svg>
                </span>
                <span _ngcontent-ng-c2194352153="" className="title_dash_nav">
                  {" "}
                  Dashboard{" "}
                </span>
              </a>
            </li>
            <li
              _ngcontent-ng-c2194352153=""
              id="headingOne"
              className="dash_nav_item"
            >
              <a
                _ngcontent-ng-c2194352153=""
                href="javascript:void(0);"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="false"
                aria-controls="collapseOne"
                className="subMenuLink collapsed"
              >
                <span _ngcontent-ng-c2194352153="" className="icon_holder">
                  <svg _ngcontent-ng-c2194352153="">
                    <use
                      _ngcontent-ng-c2194352153=""
                      xlinkHref="#information"
                    />
                  </svg>
                </span>
                <span _ngcontent-ng-c2194352153="" className="title_dash_nav">
                  {" "}
                  Admin Details{" "}
                </span>
                <span
                  _ngcontent-ng-c2194352153=""
                  className="arrowIconSubmenu"
                />
              </a>
              <ul
                _ngcontent-ng-c2194352153=""
                id="collapseOne"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
                className="subMenuSide collapse"
                style={{}}
              >
                
                
                <li _ngcontent-ng-c2194352153="">
                  <a _ngcontent-ng-c2194352153="" href="/admin">
                    Admin
                  </a>
                </li>
                <li _ngcontent-ng-c2194352153="">
                  <a _ngcontent-ng-c2194352153="" href="/sub_admin">
                    Sub Admin
                  </a>
                </li>
                
                <li _ngcontent-ng-c2194352153="">
                  <a _ngcontent-ng-c2194352153="" href="/master">
                    Master
                  </a>
                </li>
                
                <li _ngcontent-ng-c2194352153="">
                  <a _ngcontent-ng-c2194352153="" href="/super_agent">
                    Super Agent
                  </a>
                </li>
                
                <li _ngcontent-ng-c2194352153="">
                  <a _ngcontent-ng-c2194352153="" href="/agent">
                    Agent
                  </a>
                </li>
                
                <li _ngcontent-ng-c2194352153="">
                  <a _ngcontent-ng-c2194352153="" href="/client">
                    Client
                  </a>
                </li>
              </ul>
            </li>
            <li
              _ngcontent-ng-c2194352153=""
              id="headingTwo"
              className="dash_nav_item"
            >
              <a
                _ngcontent-ng-c2194352153=""
                href="javascript:void(0);"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
                className="subMenuLink collapsed"
              >
                <span _ngcontent-ng-c2194352153="" className="icon_holder">
                  <svg _ngcontent-ng-c2194352153="">
                    <use _ngcontent-ng-c2194352153="" xlinkHref="#football" />
                  </svg>
                </span>
                <span _ngcontent-ng-c2194352153="" className="title_dash_nav">
                  {" "}
                  Sport's Betting{" "}
                </span>
                <span
                  _ngcontent-ng-c2194352153=""
                  className="arrowIconSubmenu"
                />
              </a>
              <ul
                _ngcontent-ng-c2194352153=""
                id="collapseTwo"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
                className="subMenuSide collapse"
                style={{}}
              >
                <li _ngcontent-ng-c2194352153="">
                  <a _ngcontent-ng-c2194352153="" href="/game/inPlay">
                    Current Sport's Details{" "}
                  </a>
                </li>
                <li _ngcontent-ng-c2194352153="">
                  <a _ngcontent-ng-c2194352153="" href="/game/completeGame">
                    Completed Sport's Details{" "}
                  </a>
                </li>
              </ul>
            </li>
            <li
              _ngcontent-ng-c2194352153=""
              id="headingLedger"
              className="dash_nav_item"
            >
              <a
                _ngcontent-ng-c2194352153=""
                href="javascript:void(0);"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseLedger"
                aria-expanded="false"
                aria-controls="collapseLedger"
                className="subMenuLink "
              >
                <span _ngcontent-ng-c2194352153="" className="icon_holder">
                  <svg _ngcontent-ng-c2194352153="">
                    <use _ngcontent-ng-c2194352153="" xlinkHref="#file" />
                  </svg>
                </span>
                <span _ngcontent-ng-c2194352153="" className="title_dash_nav">
                  {" "}
                  Ledger{" "}
                </span>
                <span
                  _ngcontent-ng-c2194352153=""
                  className="arrowIconSubmenu"
                />
              </a>
              <ul
                _ngcontent-ng-c2194352153=""
                id="collapseLedger"
                aria-labelledby="headingLedger"
                data-bs-parent="#accordionExample"
                className="subMenuSide collapse "
                style={{}}
              >
                <li _ngcontent-ng-c2194352153="">
                  <a
                    _ngcontent-ng-c2194352153=""
                    routerlink="/ledger/myledger"
                    href="/ledger"
                  >
                    My Ledger
                  </a>
                </li>
                
                <li _ngcontent-ng-c2194352153="">
                  <a _ngcontent-ng-c2194352153="" href="/ledger/admin">
                    Admin Ledger
                  </a>
                </li>
                <li _ngcontent-ng-c2194352153="">
                  <a _ngcontent-ng-c2194352153="" href="/ledger/sub_admin">
                    Sub Admin Ledger
                  </a>
                </li>
                
                <li _ngcontent-ng-c2194352153="">
                  <a _ngcontent-ng-c2194352153="" href="/ledger/master">
                    Master Ledger
                  </a>
                </li>
                
                <li _ngcontent-ng-c2194352153="">
                  <a _ngcontent-ng-c2194352153="" href="/ledger/super_agent">
                    Super Agent Ledger
                  </a>
                </li>
                
                <li _ngcontent-ng-c2194352153="">
                  <a _ngcontent-ng-c2194352153="" href="/ledger/agent">
                    Agent Ledger
                  </a>
                </li>
                
                <li _ngcontent-ng-c2194352153="">
                  <a _ngcontent-ng-c2194352153="" href="/ledger/client">
                    All Client Ledger
                  </a>
                </li>
                <li _ngcontent-ng-c2194352153="">
                  <a
                    _ngcontent-ng-c2194352153=""
                    routerlink="/ledger/commission-lena-dena"
                    href="/ledger/commission-lena-dena"
                  >
                    Comission Lena Dena
                  </a>
                </li>
              </ul>
            </li>
            <li
              _ngcontent-ng-c2194352153=""
              className="dash_nav_item"
              tabIndex={0}
            >
              <a _ngcontent-ng-c2194352153="" href="javascript:void(0);">
                <span _ngcontent-ng-c2194352153="" className="icon_holder">
                  <svg _ngcontent-ng-c2194352153="">
                    <use _ngcontent-ng-c2194352153="" xlinkHref="#report" />
                  </svg>
                </span>
                <span _ngcontent-ng-c2194352153="" className="title_dash_nav">
                  {" "}
                  All Sub Admin Report{" "}
                </span>
              </a>
            </li>
            <li
              _ngcontent-ng-c2194352153=""
              id="headingCash"
              className="dash_nav_item"
            >
              <a
                _ngcontent-ng-c2194352153=""
                href="javascript:void(0);"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseCash"
                aria-expanded="false"
                aria-controls="collapseCash"
                className="subMenuLink collapsed"
              >
                <span _ngcontent-ng-c2194352153="" className="icon_holder">
                  <svg _ngcontent-ng-c2194352153="">
                    <use _ngcontent-ng-c2194352153="" xlinkHref="#money" />
                  </svg>
                </span>
                <span _ngcontent-ng-c2194352153="" className="title_dash_nav">
                  {" "}
                  Cash Transaction{" "}
                </span>
                <span
                  _ngcontent-ng-c2194352153=""
                  className="arrowIconSubmenu"
                />
              </a>
              <ul
                _ngcontent-ng-c2194352153=""
                id="collapseCash"
                aria-labelledby="headingCash"
                data-bs-parent="#accordionExample"
                className="subMenuSide collapse"
              >
                <li _ngcontent-ng-c2194352153="">
                  <a _ngcontent-ng-c2194352153="" href="/ct/client">
                    Debit/Credit Entry (C)
                  </a>
                </li>
                
                <li _ngcontent-ng-c2194352153="">
                  <a _ngcontent-ng-c2194352153="" href="/ct/agent">
                    Debit/Credit Entry (A)
                  </a>
                </li>
                
                <li _ngcontent-ng-c2194352153="">
                  <a _ngcontent-ng-c2194352153="" href="/ct/super_agent">
                    Debit/Credit Entry (SA)
                  </a>
                </li>
                
                <li _ngcontent-ng-c2194352153="">
                  <a _ngcontent-ng-c2194352153="" href="/ct/master">
                    Debit/Credit Entry (M)
                  </a>
                </li>
                
                <li _ngcontent-ng-c2194352153="">
                  <a _ngcontent-ng-c2194352153="" href="/ct/sub_admin">
                    Debit/Credit Entry (SUB)
                  </a>
                </li>
                <li _ngcontent-ng-c2194352153="">
                  <a _ngcontent-ng-c2194352153="" href="/ct/admin">
                    Debit/Credit Entry (AD)
                  </a>
                </li>
                
                
              </ul>
            </li>
            
            <li
              _ngcontent-ng-c2194352153=""
              id="headingReports"
              className="dash_nav_item"
            >
              <a
                _ngcontent-ng-c2194352153=""
                href="javascript:void(0);"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseReports"
                aria-expanded="false"
                aria-controls="collapseReports"
                className="subMenuLink collapsed"
              >
                <span _ngcontent-ng-c2194352153="" className="icon_holder">
                  <svg _ngcontent-ng-c2194352153="">
                    <use _ngcontent-ng-c2194352153="" xlinkHref="#report" />
                  </svg>
                </span>
                <span _ngcontent-ng-c2194352153="" className="title_dash_nav">
                  {" "}
                  Reports{" "}
                </span>
                <span
                  _ngcontent-ng-c2194352153=""
                  className="arrowIconSubmenu"
                />
              </a>
              <ul
                _ngcontent-ng-c2194352153=""
                id="collapseReports"
                aria-labelledby="headingReports"
                data-bs-parent="#accordionExample"
                className="subMenuSide collapse"
              >
                
                <li _ngcontent-ng-c2194352153="">
                  <a _ngcontent-ng-c2194352153="" href="/report/bet_history">
                    Account Statement
                  </a>
                </li>
                <li _ngcontent-ng-c2194352153="">
                  <a _ngcontent-ng-c2194352153="" href="/report/bet_history">
                    Bet History
                  </a>
                </li>
                <li _ngcontent-ng-c2194352153="">
                  <a _ngcontent-ng-c2194352153="" href="/report/current_bets">
                    Current Bets
                  </a>
                </li>
                <li _ngcontent-ng-c2194352153="">
                  <a _ngcontent-ng-c2194352153="" href="/report/login">
                    Report Details
                  </a>
                </li>
                
                
              </ul>
            </li>
          </ul> */}
        </div>
      </app-sidenav>
    </div>
  );
}
