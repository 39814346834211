import JSEncrypt from "jsencrypt";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { loginAPI } from "../service/AuthService";
import {
  APP_NAME,
  PUBLIC_KEY,
  domainMap,
  generateFingerprint,
} from "../utils/constants";
import { generateHash } from "../service";
import "./login.scoped.css";
function LoginPage() {
  const dispatch = useDispatch();
  const [userName, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [ageValid, setAgeValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const login = async (hideToast = false, password_ = false) => {
    if (!userName) {
      !hideToast && toast.error("Please enter your username");
      return;
    }
    if (!password) {
      !hideToast && toast.error("Please enter your password");
      return;
    }

    let encryptedPassword = new JSEncrypt();
    encryptedPassword.setPublicKey(PUBLIC_KEY);
    encryptedPassword = encryptedPassword.encrypt(
      password_ ? password_ : password
    );
    let fingerprint = await generateHash();
    localStorage.setItem("fingerprint", fingerprint);
    var { data, code, message, response, error } = await loginAPI(
      userName,
      encryptedPassword
    );
    if (code === 200) {
      dispatch({
        type: "accountData/setUserData",
        payload: response,
      });
      dispatch({
        type: "accountData/setLoginPopSate",
        payload: false,
      });
      window.location.href = "/";
    } else if (code == 500) {
      !hideToast && toast.error(response);
    } else if (code == 403) {
      !hideToast && toast.error(error?.description + " contact admin");
    } else if (code == 401) {
      !hideToast && toast.error(response);
    } else {
      toast.error("Something went wrong or Account is not active");
    }
  };
  /*  const location = useLocation(); */

  const currentLevel = window.location.hostname.split(".")[0];
  return (
    <div className="gx-app-login-wrap " style={{ height: "100vh" }}>
      <div className="gx-app-login-container ">
        <div className="gx-app-login-main-content ">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg" />
            <div className="gx-app-logo-wid">
              <h2 style={{ color: "white" }}>Sign In</h2>
              <p style={{ color: "white", textDecoration: "normal" }}>
                By Signing Up, you can avail full features of our services.
              </p>
            </div>
            <div className="gx-app-logo">
              <img alt="example" src="logo.png" />
            </div>
          </div>
          <div className="gx-app-login-content">
            <div
              id="basic"
              className="ant-form ant-form-horizontal gx-signin-form gx-form-row0"
            >
              <div className="ant-form-item ant-form-item-has-success">
                <div className="ant-row ant-form-item-row">
                  <div className="ant-col ant-form-item-control">
                    <div className="ant-form-item-control-input">
                      <div className="ant-form-item-control-input-content">
                        <input
                          type="text"
                          id="userName"
                          onChange={(e) => setUsername(e.target.value)}
                          value={userName}
                          name="userName"
                          defaultValue=""
                          placeholder="Username *"
                          aria-required="true"
                          className="ant-input ant-input-status-success gx-border-redius0"
                          style={{ textTransform: "uppercase" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ant-form-item ant-form-item-has-success">
                <div className="ant-row ant-form-item-row">
                  <div className="ant-col ant-form-item-control">
                    <div className="ant-form-item-control-input">
                      <div className="ant-form-item-control-input-content">
                        <input
                          type="password"
                          onChange={(e) => setPassword(e.target.value)}
                          id="password"
                          placeholder="Password *"
                          defaultValue=""
                          aria-required="true"
                          className="ant-input ant-input-status-success gx-border-redius0"
                          style={{ textTransform: "uppercase" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button
                type="submit"
                className="ant-btn ant-btn-primary gx-mb-0"
                onClick={() => login(false, password)}
              >
                Sign In
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default LoginPage;
