import React, { useEffect, useState } from "react";
import { dashboardBannerAPI, getAllMatchesAPI } from "../service/AuthService";
import { useDispatch } from "react-redux";
import store from "../redux/store";
import { Link, useNavigate } from "react-router-dom";
import { PERMISSION_MAP } from "../utils/constants";
import {
  getAllCricketMatchListAPI,
  getAllMatchesOddsCricketAPI,
} from "../service/cricket";
import moment from "moment";

export default function Dashboard() {
  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleOpenModal = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };
  const [homeImages, setHomeImages] = React.useState([]);
  const userData = store.getState().account.userData;
  const getBannerImages = async () => {
    var { response, code } = await dashboardBannerAPI();
    if (code === 200) {
      setHomeImages(response);
    }
  };
  const dispatch = useDispatch();
  useEffect(() => {
    getBannerImages();
  }, []);
  const [matches, setMatches] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedTree, setSelectedTree] = useState(null);
  const navigate = useNavigate();
  const getAllMatches = async () => {
    const { response } = await getAllMatchesAPI(0, false);
    setMatches(response);
  };

  useEffect(() => {
    getAllMatches();
  }, [currentPage]);
  const linkTree = {
    admin: [
      {
        name: "Admin",
        link: "/admin",
        icon: "fa fa-user",
        isPermission: true,
        enum: "ADMIN",
      },
      {
        name: "Sub Admin",
        link: "/sub_admin",
        icon: "fa fa-user",
        isPermission: true,
        enum: "SUB_ADMIN",
      },
      {
        name: "Master",
        link: "/master",
        icon: "fa fa-user",
        isPermission: true,
        enum: "MASTER",
      },
      {
        name: "Super Agent",
        link: "/super_agent",
        icon: "fa fa-user",
        isPermission: true,
        enum: "SUPER_AGENT",
      },
      {
        name: "Agent",
        link: "/agent",
        icon: "fa fa-user",
        isPermission: true,
        enum: "AGENT",
      },
      {
        name: "Client",
        link: "/client",
        icon: "fa fa-user",
        isPermission: true,
        enum: "CLIENT",
      },
    ],

    ledger: [
      {
        name: "Admin Ledger",
        link: "/ledger/admin",
        icon: "fa fa-user",
        isPermission: true,
        enum: "ADMIN",
      },
      {
        name: "Sub Admin Ledger",
        link: "/ledger/sub_admin",
        icon: "fa fa-user",
        isPermission: true,
        enum: "SUB_ADMIN",
      },
      {
        name: "Master Ledger",
        link: "/ledger/master",
        icon: "fa fa-user",
        isPermission: true,
        enum: "MASTER",
      },
      {
        name: "Super Agent Ledger",
        link: "/ledger/super_agent",
        icon: "fa fa-user",
        isPermission: true,
        enum: "SUPER_AGENT",
      },
      {
        name: "Agent Ledger",
        link: "/ledger/agent",
        icon: "fa fa-user",
        isPermission: true,
        enum: "AGENT",
      },
      {
        name: "Client Ledger",
        link: "/ledger/client",
        icon: "fa fa-user",
        isPermission: true,
        enum: "CLIENT",
      },
    ],
    cash: [
      {
        name: "Debit/Credit Entry (A)",
        link: "/ct/admin",
        icon: "fa fa-user",
        isPermission: true,
        enum: "ADMIN",
      },
      {
        name: "Debit/Credit Entry (SA)",
        link: "/ct/sub_admin",
        icon: "fa fa-user",
        isPermission: true,
        enum: "SUB_ADMIN",
      },
      {
        name: "Debit/Credit Entry (M)",
        link: "/ct/master",
        icon: "fa fa-user",
        isPermission: true,
        enum: "MASTER",
      },
      {
        name: "Debit/Credit Entry (SA)",
        link: "/ct/super_agent",
        icon: "fa fa-user",
        isPermission: true,
        enum: "SUPER_AGENT",
      },
      {
        name: "Debit/Credit Entry (A)",
        link: "/ct/agent",
        icon: "fa fa-user",
        isPermission: true,
        enum: "AGENT",
      },
      {
        name: "Debit/Credit Entry (C)",
        link: "/ct/client",
        icon: "fa fa-user",
        isPermission: true,
        enum: "CLIENT",
      },
    ],
  };

  const userPermissions = PERMISSION_MAP[userData?.userType?.toUpperCase()];
  const renderComponent = (link, backgroundColor, text, number) => (
    <div className="col-lg-6 col-12" key={link}>
      <a href={link} />
      <div className={`small-box ${backgroundColor}`}>
        <a href={link}>
          <div className="inner" style={{ padding: 12 }}>
            <h3>{number}</h3>
            <p>{text}</p>
          </div>
          <div className="icon">
            <i className="ion ion-bag" />
          </div>
        </a>
        <a href={link} className="small-box-footer">
          More Info <i className="fas fa-arrow-circle-right" />
        </a>
      </div>
    </div>
  );

  return (
    <div _ngcontent-ng-c3705319800="" className="main_content">
      <router-outlet _ngcontent-ng-c3705319800="" />
      <app-dashboard _nghost-ng-c2891388947="">
        <div _ngcontent-ng-c2891388947="" className="page_header">
          <h1 _ngcontent-ng-c2891388947="" className="page_heading">
            Dashboard
          </h1>
          <div _ngcontent-ng-c2891388947="" className="breadcrumb_block">
            <nav _ngcontent-ng-c2891388947="" aria-label="breadcrumb">
              <ol _ngcontent-ng-c2891388947="" className="breadcrumb">
                <li _ngcontent-ng-c2891388947="" className="breadcrumb-item">
                  <a
                    _ngcontent-ng-c2891388947=""
                    onClick={() => {
                      window.location.reload();
                    }}
                  >
                    Home
                  </a>
                </li>
                <li
                  _ngcontent-ng-c2891388947=""
                  aria-current="page"
                  className="breadcrumb-item active"
                >
                  Dashboard
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div _ngcontent-ng-c2891388947="" className="px-md-0 px-2">
          <div _ngcontent-ng-c2891388947="" className="row g-3">
            <div className="ant-col ant-col-xs-24 ant-col-lg-24">
              <div className="ant-row gx-pb-10">
                {/* <div className="ant-col ant-col-xs-12 ant-col-sm-12 ant-col-md-12 ant-col-lg-12 ant-col-xl-6">
                      <div>
                        <div className="ant-card ant-card-bordered gx-card-widget gx-card-full gx-bg-transparent">
                          <div className="ant-card-body">
                            <div className="gx-fillchart   gx-overlay-fillchart gx-bg-transparent">
                              <div
                                className="gx-media gx-align-items-center gx-pointer  gx-flex-nowrap gx-fillchart-content "
                                style={{ borderRadius: 20 }}
                                onClick={() => setSelectedTree("admin")}
                              >
                                <div className="gx-mr-1 gx-mr-xl-3">
                                  <i className="icon icon-avatar gx-fs-2xl" />
                                </div>
                                <div className="gx-media-body">
                                  <h1 className="gx-fs-lg gx-text-capitalize  gx-font-weight-semi-bold  gx-text-white">
                                    {userData?.userType} Details
                                  </h1>
                                  <h1 className="gx-fs-lg gx-text-capitalize gx-font-weight-semi-bold gx-text-white" />
                                  <h1 className="gx-fs-lg  gx-text-capitalize gx-text-white" />
                                  <p className="gx-mb-0" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                {/* The card that opens the modal */}
                <div className="ant-col ant-col-xs-12 ant-col-sm-12 ant-col-md-12 ant-col-lg-12 ant-col-xl-6">
                  <div>
                    <div className="ant-card ant-card-bordered gx-card-widget gx-card-full gx-bg-transparent">
                      <div className="ant-card-body">
                        <div className="gx-fillchart gx-overlay-fillchart gx-bg-transparent">
                          <div
                            className="gx-media gx-align-items-center gx-pointer gx-flex-nowrap gx-fillchart-content"
                            style={{ borderRadius: 20 }}
                            onClick={() => {
                              setSelectedTree("admin");
                              handleOpenModal();
                            }}
                          >
                            <div className="gx-mr-1 gx-mr-xl-3">
                              <i className="icon icon-avatar gx-fs-2xl" />
                            </div>
                            <div className="gx-media-body">
                              <h1 className="gx-fs-lg gx-text-capitalize gx-font-weight-semi-bold gx-text-white">
                                {userData?.userType} Details
                              </h1>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* The Modal */}
                {isModalVisible && (
                  <div
                    tabIndex={-1}
                    className="ant-modal-wrap"
                    style={{
                      backgroundColor: "rgba(0, 0, 0, 0.6)",
                      position: "fixed",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      zIndex: 1000,
                    }}
                  >
                    <div
                      role="dialog"
                      aria-modal="true"
                      className="ant-modal gx-px-3"
                      style={{ width: 520, zIndex: 1050 }}
                    >
                      <div className="ant-modal-content">
                        <button
                          type="button"
                          aria-label="Close"
                          className="ant-modal-close"
                          onClick={handleCloseModal}
                        >
                          <span className="ant-modal-close-x">
                            <span
                              role="img"
                              aria-label="close"
                              className="anticon anticon-close gx-text-black"
                            >
                              <svg
                                fillRule="evenodd"
                                viewBox="64 64 896 896"
                                focusable="false"
                                data-icon="close"
                                width="1em"
                                height="1em"
                                fill="currentColor"
                                aria-hidden="true"
                              >
                                <path d="M799.86 166.31l57.69 57.7a.12.12 0 010 .07l-287.7 287.7 287.7 287.7a.12.12 0 010 .07l-57.7 57.69a.12.12 0 01-.07 0l-287.7-287.7-287.7 287.7a.12.12 0 01-.07 0l-57.69-57.7a.12.12 0 010-.07l287.7-287.7-287.7-287.7a.12.12 0 010-.07l57.7-57.69a.12.12 0 01.07 0l287.7 287.7 287.7-287.7z" />
                              </svg>
                            </span>
                          </span>
                        </button>

                        <div className="ant-modal-body">
                          <div className="ant-row">
                            {/* Modal content */}
                            {linkTree[selectedTree].map((item) => {
                              if (item.isPermission) {
                                if (userPermissions.includes(item.enum)) {
                                  return (
                                    <div className="ant-col ant-col-xs-24 ant-col-md-12">
                                      <a href={item?.link}>
                                        <div className="ant-card ant-card-bordered gx-card-widget gx-card-full gx-bg-transparent">
                                          <div className="ant-card-body">
                                            <div className="gx-fillchart gx-overlay-fillchart gx-bg-transparent">
                                              <div
                                                className="gx-media gx-align-items-center gx-pointer gx-flex-nowrap gx-fillchart-content"
                                                style={{ borderRadius: 20 }}
                                              >
                                                <div className="gx-mr-1 gx-mr-xl-3">
                                                  <i className="icon icon-avatar gx-fs-2xl" />
                                                </div>
                                                <div className="gx-media-body">
                                                  <h1 className="gx-fs-lg gx-text-capitalize gx-font-weight-semi-bold gx-text-white">
                                                    {item.name}
                                                  </h1>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </a>
                                    </div>
                                  );
                                } else {
                                  return null;
                                }
                              } else {
                                return null;
                              }
                            })}
                          </div>
                        </div>

                        <div className="ant-modal-footer">
                          <button
                            type="button"
                            className="ant-btn ant-btn-default gx-bg-grey gx-text-white gx-border-redius0"
                            onClick={handleCloseModal}
                          >
                            <span> Close </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="ant-col ant-col-xs-12 ant-col-sm-12 ant-col-md-12 ant-col-lg-12 ant-col-xl-6">
                  <div>
                    <div className="ant-card ant-card-bordered gx-card-widget gx-card-full gx-bg-transparent">
                      <div className="ant-card-body">
                        <div className="gx-fillchart   gx-overlay-fillchart gx-bg-transparent">
                          <div
                            className="gx-media gx-align-items-center gx-pointer  gx-flex-nowrap gx-fillchart-content "
                            style={{ borderRadius: 20 }}
                            onClick={() => navigate("/inplay")}
                          >
                            <div className="gx-mr-1 gx-mr-xl-3">
                              <i className="icon icon-chart gx-fs-2xl" />
                            </div>
                            <div className="gx-media-body">
                              <h1 className="gx-fs-lg gx-text-capitalize  gx-font-weight-semi-bold  gx-text-white">
                                Sport's Details
                              </h1>
                              <h1 className="gx-fs-lg gx-text-capitalize gx-font-weight-semi-bold gx-text-white" />
                              <h1 className="gx-fs-lg  gx-text-capitalize gx-text-white" />
                              <p className="gx-mb-0" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ant-col ant-col-xs-12 ant-col-sm-12 ant-col-md-12 ant-col-lg-12 ant-col-xl-6">
                  <div>
                    <div className="ant-card ant-card-bordered gx-card-widget gx-card-full gx-bg-transparent">
                      <div className="ant-card-body">
                        <div className="gx-fillchart   gx-overlay-fillchart gx-bg-transparent">
                          <div
                            className="gx-media gx-align-items-center gx-pointer  gx-flex-nowrap gx-fillchart-content "
                            style={{ borderRadius: 20 }}
                            onClick={() => {
                              setSelectedTree("ledger");
                              handleOpenModal();
                            }}
                          >
                            <div className="gx-mr-1 gx-mr-xl-3">
                              <i className="icon icon-avatar gx-fs-2xl" />
                            </div>
                            <div className="gx-media-body">
                              <h1 className="gx-fs-lg gx-text-capitalize  gx-font-weight-semi-bold  gx-text-white">
                                Ledger
                              </h1>
                              <h1 className="gx-fs-lg gx-text-capitalize gx-font-weight-semi-bold gx-text-white" />
                              <h1 className="gx-fs-lg  gx-text-capitalize gx-text-white" />
                              <p className="gx-mb-0" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ant-col ant-col-xs-12 ant-col-sm-12 ant-col-md-12 ant-col-lg-12 ant-col-xl-6">
                  <div>
                    <div className="ant-card ant-card-bordered gx-card-widget gx-card-full gx-bg-transparent">
                      <div className="ant-card-body">
                        <div className="gx-fillchart   gx-overlay-fillchart gx-bg-transparent">
                          <div
                            className="gx-media gx-align-items-center gx-pointer  gx-flex-nowrap gx-fillchart-content "
                            style={{ borderRadius: 20 }}
                            onClick={() => {
                              setSelectedTree("cash");
                              handleOpenModal();
                            }}
                          >
                            <div className="gx-mr-1 gx-mr-xl-3">
                              <i className="icon icon-avatar gx-fs-2xl" />
                            </div>
                            <div className="gx-media-body">
                              <h1 className="gx-fs-lg gx-text-capitalize  gx-font-weight-semi-bold  gx-text-white">
                                Cash Transanction
                              </h1>
                              <h1 className="gx-fs-lg gx-text-capitalize gx-font-weight-semi-bold gx-text-white" />
                              <h1 className="gx-fs-lg  gx-text-capitalize gx-text-white" />
                              <p className="gx-mb-0" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ant-row">
                <div className="ant-col ant-col-xs-12 ant-col-sm-12 ant-col-md-12 ant-col-lg-12 ant-col-xl-6">
                  <div>
                    <div className="ant-card ant-card-bordered gx-card-widget gx-card-full gx-bg-transparent">
                      <div className="ant-card-body">
                        <div className="gx-fillchart   gx-overlay-fillchart gx-bg-transparent">
                          <div
                            className="gx-media gx-align-items-center gx-pointer  gx-flex-nowrap gx-fillchart-content "
                            style={{ borderRadius: 20 }}
                          >
                            <div className="gx-mr-1 gx-mr-xl-3">
                              <i className="icon icon-setting gx-fs-2xl" />
                            </div>
                            <div className="gx-media-body">
                              <h1 className="gx-fs-lg gx-text-capitalize  gx-font-weight-semi-bold  gx-text-white">
                                Settings
                              </h1>
                              <h1 className="gx-fs-lg gx-text-capitalize gx-font-weight-semi-bold gx-text-white" />
                              <h1 className="gx-fs-lg  gx-text-capitalize gx-text-white" />
                              <p className="gx-mb-0" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ant-col ant-col-xs-12 ant-col-sm-12 ant-col-md-12 ant-col-lg-12 ant-col-xl-6">
                  <div className="ant-card ant-card-bordered gx-card-widget gx-card-full gx-bg-transparent">
                    <div className="ant-card-body">
                      <div className="gx-fillchart   gx-overlay-fillchart gx-bg-transparent">
                        <div
                          className="gx-media gx-align-items-center gx-pointer  gx-flex-nowrap gx-fillchart-content "
                          style={{ borderRadius: 20 }}
                          onClick={() => {
                            localStorage.clear();
                            navigate("/login");
                            window.location.reload();
                          }}
                        >
                          <div className="gx-mr-1 gx-mr-xl-3">
                            <i className="icon icon-signin gx-fs-2xl" />
                          </div>
                          <div className="gx-media-body">
                            <h1 className="gx-fs-lg gx-text-capitalize  gx-font-weight-semi-bold  gx-text-white">
                              Logout
                            </h1>
                            <h1 className="gx-fs-lg gx-text-capitalize gx-font-weight-semi-bold gx-text-white" />
                            <h1 className="gx-fs-lg  gx-text-capitalize gx-text-white" />
                            <p className="gx-mb-0" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ant-row">
                <div className="ant-col ant-col-xs-12 ant-col-sm-12 ant-col-md-12 ant-col-lg-12 ant-col-xl-6">
                  <div className="ant-card ant-card-bordered gx-card-widget gx-card-full gx-bg-transparent">
                    <div className="ant-card-body">
                      <div className="gx-fillchart   gx-overlay-fillchart gx-bg-transparent">
                        <div
                          className="gx-media gx-align-items-center gx-pointer  gx-flex-nowrap gx-fillchart-content "
                          style={{ borderRadius: 20 }}
                        >
                          <div className="gx-mr-1 gx-mr-xl-3">
                            <i className="icon icon-user gx-fs-2xl" />
                          </div>
                          <div className="gx-media-body">
                            <h1 className="gx-fs-lg gx-text-capitalize  gx-font-weight-semi-bold  gx-text-white">
                              {userData?.username}
                            </h1>
                            <h1 className="gx-fs-lg gx-text-capitalize gx-font-weight-semi-bold gx-text-white" />
                            <h1 className="gx-fs-lg  gx-text-capitalize gx-text-white" />
                            <p className="gx-mb-0">
                              You are {userData?.userType}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ant-col ant-col-xs-12 ant-col-sm-12 ant-col-md-12 ant-col-lg-12 ant-col-xl-6">
                  <div className="ant-card ant-card-bordered gx-card-widget gx-card-full gx-bg-transparent">
                    <div className="ant-card-body">
                      <div className="gx-fillchart   gx-overlay-fillchart gx-bg-transparent">
                        <div
                          className="gx-media gx-align-items-center gx-pointer  gx-flex-nowrap gx-fillchart-content "
                          style={{ borderRadius: 20 }}
                        >
                          <div className="gx-mr-1 gx-mr-xl-3">
                            <i className="icon icon-diamond gx-fs-2xl" />
                          </div>
                          <div className="gx-media-body">
                            <h1 className="gx-fs-lg gx-text-capitalize  gx-font-weight-semi-bold  gx-text-white" />
                            <h1 className="gx-fs-lg gx-text-capitalize gx-font-weight-semi-bold gx-text-white" />
                            <h1 className="gx-fs-lg  gx-text-capitalize gx-text-white">
                              {userData?.balance}
                            </h1>
                            <p className="gx-mb-0">Chips</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ant-col ant-col-xs-12 ant-col-sm-12 ant-col-md-12 ant-col-lg-12 ant-col-xl-6">
                  <div className="ant-card ant-card-bordered gx-card-widget gx-card-full gx-bg-transparent">
                    <div className="ant-card-body">
                      <div className="gx-fillchart   gx-overlay-fillchart gx-bg-transparent">
                        <div
                          className="gx-media gx-align-items-center gx-pointer  gx-flex-nowrap gx-fillchart-content "
                          style={{ borderRadius: 20 }}
                        >
                          <div className="gx-mr-1 gx-mr-xl-3">
                            <i className="icon icon-team gx-fs-2xl" />
                          </div>
                          <div className="gx-media-body">
                            <h1 className="gx-fs-lg gx-text-capitalize  gx-font-weight-semi-bold  gx-text-white">
                              1
                            </h1>
                            <h1 className="gx-fs-lg gx-text-capitalize gx-font-weight-semi-bold gx-text-white" />
                            <h1 className="gx-fs-lg  gx-text-capitalize gx-text-white" />
                            <p className="gx-mb-0">Members</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ant-col ant-col-xs-12 ant-col-sm-12 ant-col-md-12 ant-col-lg-12 ant-col-xl-6">
                  <div className="ant-card ant-card-bordered gx-card-widget gx-card-full gx-bg-transparent">
                    <div className="ant-card-body">
                      <div className="gx-fillchart   gx-overlay-fillchart gx-bg-transparent">
                        <div
                          className="gx-media gx-align-items-center gx-pointer  gx-flex-nowrap gx-fillchart-content "
                          style={{ borderRadius: 20 }}
                        >
                          <div className="gx-mr-1 gx-mr-xl-3">
                            <i className="icon icon-chart gx-fs-2xl" />
                          </div>
                          <div className="gx-media-body">
                            <h1 className="gx-fs-lg gx-text-capitalize  gx-font-weight-semi-bold  gx-text-white">
                              {
                                userData?.partnershipAndCommission
                                  ?.myPartnership
                              }
                            </h1>
                            <h1 className="gx-fs-lg gx-text-capitalize gx-font-weight-semi-bold gx-text-white" />
                            <h1 className="gx-fs-lg  gx-text-capitalize gx-text-white" />
                            <p className="gx-mb-0">My Share</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ant-col ant-col-xs-12 ant-col-sm-12 ant-col-md-12 ant-col-lg-12 ant-col-xl-6">
                  <div className="ant-card ant-card-bordered gx-card-widget gx-card-full gx-bg-transparent">
                    <div className="ant-card-body">
                      <div className="gx-fillchart   gx-overlay-fillchart gx-bg-transparent">
                        <div
                          className="gx-media gx-align-items-center gx-pointer  gx-flex-nowrap gx-fillchart-content "
                          style={{ borderRadius: 20 }}
                        >
                          <div className="gx-mr-1 gx-mr-xl-3">
                            <i className="icon icon-chart gx-fs-2xl" />
                          </div>
                          <div className="gx-media-body">
                            <h1 className="gx-fs-lg gx-text-capitalize  gx-font-weight-semi-bold  gx-text-white">
                              {100 -
                                userData?.partnershipAndCommission
                                  ?.myPartnership}
                            </h1>
                            <h1 className="gx-fs-lg gx-text-capitalize gx-font-weight-semi-bold gx-text-white" />
                            <h1 className="gx-fs-lg  gx-text-capitalize gx-text-white" />
                            <p className="gx-mb-0">Company Share</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ant-col ant-col-xs-12 ant-col-sm-12 ant-col-md-12 ant-col-lg-12 ant-col-xl-6">
                  <div className="ant-card ant-card-bordered gx-card-widget gx-card-full gx-bg-transparent">
                    <div className="ant-card-body">
                      <div className="gx-fillchart   gx-overlay-fillchart gx-bg-transparent">
                        <div
                          className="gx-media gx-align-items-center gx-pointer  gx-flex-nowrap gx-fillchart-content "
                          style={{ borderRadius: 20 }}
                        >
                          <div className="gx-mr-1 gx-mr-xl-3">
                            <i className="icon icon-undefined gx-fs-2xl" />
                          </div>
                          <div className="gx-media-body">
                            <h1 className="gx-fs-lg gx-text-capitalize  gx-font-weight-semi-bold  gx-text-white" />
                            <h1 className="gx-fs-lg gx-text-capitalize gx-font-weight-semi-bold gx-text-white">
                              {
                                userData?.partnershipAndCommission
                                  ?.matchCommission
                              }
                              %
                            </h1>
                            <h1 className="gx-fs-lg  gx-text-capitalize gx-text-white" />
                            <p className="gx-mb-0">Match Comminssion</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ant-col ant-col-xs-12 ant-col-sm-12 ant-col-md-12 ant-col-lg-12 ant-col-xl-6">
                  <div className="ant-card ant-card-bordered gx-card-widget gx-card-full gx-bg-transparent">
                    <div className="ant-card-body">
                      <div className="gx-fillchart   gx-overlay-fillchart gx-bg-transparent">
                        <div
                          className="gx-media gx-align-items-center gx-pointer  gx-flex-nowrap gx-fillchart-content "
                          style={{ borderRadius: 20 }}
                        >
                          <div className="gx-mr-1 gx-mr-xl-3">
                            <i className="icon icon-undefined gx-fs-2xl" />
                          </div>
                          <div className="gx-media-body">
                            <h1 className="gx-fs-lg gx-text-capitalize  gx-font-weight-semi-bold  gx-text-white" />
                            <h1 className="gx-fs-lg gx-text-capitalize gx-font-weight-semi-bold gx-text-white">
                              {
                                userData?.partnershipAndCommission
                                  ?.sessionCommission
                              }
                              %
                            </h1>
                            <h1 className="gx-fs-lg  gx-text-capitalize gx-text-white" />
                            <p className="gx-mb-0">Session Comminssion</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ant-col ant-col-xs-12 ant-col-sm-12 ant-col-md-12 ant-col-lg-11 ant-col-xl-6">
                  <a href="/components/rules/rules-regulation">
                    <div className="ant-card ant-card-bordered gx-card-widget gx-card-full gx-bg-transparent">
                      <div className="ant-card-body">
                        <div className="gx-fillchart   gx-overlay-fillchart gx-bg-transparent">
                          <div
                            className="gx-media gx-align-items-center gx-pointer  gx-flex-nowrap gx-fillchart-content "
                            style={{ borderRadius: 20 }}
                          >
                            <div className="gx-mr-1 gx-mr-xl-3">
                              <i className="icon icon-sweet-alert gx-fs-2xl" />
                            </div>
                            <div className="gx-media-body">
                              <h1 className="gx-fs-lg gx-text-capitalize  gx-font-weight-semi-bold  gx-text-white">
                                Rules
                              </h1>
                              <h1 className="gx-fs-lg gx-text-capitalize gx-font-weight-semi-bold gx-text-white" />
                              <h1 className="gx-fs-lg  gx-text-capitalize gx-text-white" />
                              <p className="gx-mb-0" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div className="ant-card ant-card-bordered">
                <div className="ant-card-head">
                  <div className="ant-card-head-wrapper">
                    <div className="ant-card-head-title">Active Matches </div>
                  </div>
                </div>
                <div className="ant-card-body">
                  <div className="ant-table-wrapper gx-table-responsive gx-text-uppercase">
                    <div className="ant-spin-nested-loading">
                      <div className="ant-spin-container">
                        <div className="ant-table ant-table-small ant-table-bordered">
                          <div className="ant-table-container">
                            <div className="ant-table-content">
                              <table style={{ tableLayout: "auto" }}>
                                <colgroup />
                                <thead className="ant-table-thead">
                                  <tr>
                                    <th className="ant-table-cell">S/N</th>
                                    <th className="ant-table-cell">Name</th>
                                    <th className="ant-table-cell">
                                      Open Date
                                    </th>
                                    <th className="ant-table-cell">
                                      Competition
                                    </th>
                                    <th className="ant-table-cell">Inplay</th>
                                    <th className="ant-table-cell">
                                      Details
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className="ant-table-tbody">
                                  {matches?.content?.map((item) => (
                                    <tr
                                      data-row-key="67153d8fea102b7eb7945e53"
                                      className="ant-table-row ant-table-row-level-0"
                                    >
                                      <td className="ant-table-cell">1</td>
                                      <td className="ant-table-cell">
                                        {item?.eventName}
                                      </td>
                                      <td className="ant-table-cell">
                                        <span className="">
                                          {moment(
                                            item?.eventDate + "Z"
                                          ).format("DD-MM-YYYY hh:mm A")}
                                        </span>
                                      </td>
                                      <td className="ant-table-cell">
                                        {item?.sport}
                                      </td>
                                      <td className="ant-table-cell">
                                        <span
                                          className="ant-tag ant-tag-has-color gx-border-redius0"
                                          style={{
                                            backgroundColor:
                                              "rgb(16, 142, 233)",
                                          }}
                                        />
                                      </td>
                                      <td className="ant-table-cell">
                                        <a
                                          href="/components/matchviewdetail/match-view-details/1.234558634/33697416"
                                          className="link-button gx-font-weight-semi-bold"
                                        >
                                          Details
                                        </a>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </app-dashboard>
    </div>
  );
}
